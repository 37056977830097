import {Component, OnInit} from '@angular/core'
import {ActivatedRoute} from '@angular/router'
import {FotoService} from '../services/foto.service'

@Component({
  selector: 'aku-registered',
  templateUrl: './registered.component.html',
  styleUrls: ['./registered.component.scss']
})
export class RegisteredComponent implements OnInit {

  constructor(
    private fotoService: FotoService
  ) {
  }

  ngOnInit() {
    this.fotoService.getFotos().subscribe({
      next: value => console.log(value)
    })

  }
}
