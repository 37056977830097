import {Component} from '@angular/core'
import {environment} from '../../environments/environment'
import {Router} from '@angular/router'
import {FormControl, FormGroup, Validators} from '@angular/forms'

@Component({
  selector: 'aku-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  public bankIdUrl = environment.apiUrl
  public service = environment.service

  public form: FormGroup = new FormGroup({
    description: new FormControl<string>('')
  })

  constructor(
    private router: Router
  ) {
  }

  public sendSignData(): any {
    return this.form.value
  }

  public afterSigned() {
    return this.router.navigate(['/registered'])
  }
}
