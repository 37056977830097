<div class="holder">
  <div class="text-holder">
    <h3>Välkommen till Fotografera!</h3>

    <form [formGroup]="form">
      <mat-form-field class="description">
        <mat-label>Beskrivning (kan lämnas tom)</mat-label>
        <textarea
            cdkAutosizeMaxRows="3"
            cdkAutosizeMinRows="3"
            cdkTextareaAutosize
            formControlName="description"
            matInput></textarea>
      </mat-form-field>

    </form>

    <aku-bankid
        [service]="service"
        [bankIdUrl]="bankIdUrl"
        (accessToken)="afterSigned()"
        [userRef]="sendSignData()"
    ></aku-bankid>
  </div>

  <div class="scrollable-textarea">
    <div>
      <h3>Samtycke till att använda bilder och filmer för att marknadsföra Helsingborgs stad</h3>
      <h4>Information om bild eller film</h4>
      <p>Beskrivning:</p>
      <p>Fotograf:</p>
      <p>Datum:</p>
      <h4>Så här behandlar vi dina eller ditt barns personuppgifter</h4>
      <p>Vi lagrar och publicerar bilder och filmer för att informera om eller marknadsföra oss. När vi gör det
        kan vi också dela med oss av bilder och filmer till stadens olika förvaltningar och bolag, andra
        kommuner, landsting och föreningar, som vi samarbetar med.</p>
      <p>Till exempel kan bild och film visas i presentationer, annonser, olika typer av samhällsinformation samt
        digitala kanaler så som sociala medier och webbplatser (exempelvis på dunkerskulturhus.se,
        fredriksdal.se eller våra sidor på helsingborg.se).</p>
      <p>Kontaktuppgifterna på denna blankett och bild och film på dig eller ditt barn är personuppgifter. För att
        vi ska få behandla dessa uppgifter krävs samtycke från dig.</p>
      <p>Du kan när som helst ta tillbaka ditt samtycke. Vi kommer då inte längre att behandla personuppgifter om
        dig eller ditt barn för det ändamål som samtycket gäller för. Uppgifter om dig kommer att arkiveras
        eller gallras enligt våra rutiner för arkivering.</p>
      <h4>Ditt samtycke</h4>
      <p>Jag samtycker till att ni behandlar mina eller mitt barns personuppgifter på det sätt och för det ändamål
        som anges ovan.</p>
      <h5>Informationen samlas in av Akiru Studios AB som också är personuppgiftsansvarig. Du kan kontakta oss för
        att få information om vilka uppgifter som behandlas om dig eller för att få dina uppgifter rättade. Du
        kan även kontakta vårt dataskyddsombud på dataprotectionofficer@akirustudios.se. Du har rätt att klaga
        till tillsynsmyndigheten Datainspektionen om du tycker att vi behandlar dina personuppgifter på ett
        felaktigt sätt.</h5>
    </div>
  </div>


</div>