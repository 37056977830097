<div class="holder">
  <div class="header-holder">
    <div [routerLink]="['/']" class="logo-holder">
      <img src="assets/foto-logo.png" alt="foto logo">
    </div>
    <div class="menu-holder" *ngIf="configService.isLoggedIn | async as loggedIn">
      <button [matMenuTriggerFor]="menu" mat-button>
        <mat-icon class="icon">menu</mat-icon>
      </button>
      <mat-menu #menu>
        <button *ngIf="loggedIn" [routerLink]="['/registered']" mat-menu-item>Genomförda</button>
        <button *ngIf="loggedIn" (click)="logout()" mat-menu-item>Logga ut</button>
      </mat-menu>
    </div>
  </div>
</div>