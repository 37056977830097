import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {BankIdModule} from '@akiru/bankid'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {MatButtonModule} from '@angular/material/button'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {AuthInterceptor} from './application/auth-interceptor'
import {LOCAL_STORAGE_PROVIDERS} from './application/local-storage.provider'
import {MatInputModule} from '@angular/material/input'
import {MatSelectModule} from '@angular/material/select'
import {HeaderComponent} from './common/header/header.component'
import { FooterComponent } from './common/footer/footer.component'
import { LoginComponent } from './2-login/login.component'
import {MatMenuModule} from "@angular/material/menu"
import {MatIconModule} from "@angular/material/icon"
import { ErrorDialogComponent } from './common/error-dialog/error-dialog.component'
import {MatDialogModule} from '@angular/material/dialog'
import { HomeComponent } from './3-home/home.component'
import {ReactiveFormsModule} from '@angular/forms'
import {MatSnackBarModule} from '@angular/material/snack-bar'
import { RegisteredComponent } from './5-registered/registered.component'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    ErrorDialogComponent,
    HomeComponent,
    RegisteredComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BankIdModule,
    BrowserAnimationsModule,
    MatButtonModule,
    HttpClientModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSnackBarModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {floatLabel: 'always', hideRequiredMarker: true, appearance: 'outline'}
    },
    LOCAL_STORAGE_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
