import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {LoginComponent} from './2-login/login.component'
import {authGuard} from './application/auth.guard'
import {HOME_ROUTE_PATH} from './application/data-types'
import {HomeComponent} from './3-home/home.component'
import {RegisteredComponent} from './5-registered/registered.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: HOME_ROUTE_PATH,
    component: HomeComponent,
    canActivate: [authGuard]
  },
  {
    path: 'registered',
    component: RegisteredComponent,
    canActivate: [authGuard]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
