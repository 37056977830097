import {Injectable} from '@angular/core'
import {environment} from '../../environments/environment'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class FotoService {
  constructor(private httpClient: HttpClient) {
  }

  public getFotos(): Observable<any> {
    const url = `${environment.apiUrl}/foto`
    return this.httpClient.get<any>(url)
  }
}
