import {Component, OnInit} from '@angular/core'
import {environment} from '../../environments/environment'
import {ConfigService} from '../services/config.service'
import {filter} from 'rxjs'
import {Router} from '@angular/router'
import {HOME_ROUTE_PATH} from '../application/data-types'

@Component({
  selector: 'aku-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  bankIdUrl = environment.bankIdUrl

  constructor(
    public configService: ConfigService,
    private router: Router
  ) {
  }

  public ngOnInit(): void {
    this.configService.checkLoggedIn()
    this.configService.isLoggedIn.pipe(
      filter(Boolean)
    ).subscribe({
      next: () => this.router.navigate(['/', HOME_ROUTE_PATH])
    })
  }

}
