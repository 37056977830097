import { Component } from '@angular/core'

@Component({
  selector: 'aku-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

}
